<template>
  <div class="vuse-content-wrapper">
    <v-row no-gutters align="center" justify="space-between">
      <smart-breadcrumbs :items="navigationElements" />
      <help :helpId="50" />
    </v-row>
    <v-container fluid>
      <v-row class="mx-0">
        <v-col class="align-self-center pa-0" align="center">
          <h2>Mis Cursos</h2>
        </v-col>
      </v-row>

      <v-row class="mx-0" v-if="courses && courses.length > 0">
        <v-col cols="12" xl="3" md="4" sm="6" v-for="course in courses" :key="course.id">
          <course-card :active="1" v-bind="course" />
        </v-col>
      </v-row>
      <v-row class="mx-0" v-else-if="loading">
        <v-col class="mx-auto" cols="12" xl="3" md="4" sm="6" v-for="n in 6" :key="n">
          <v-skeleton-loader class="mx-auto" type="card" />
        </v-col>
      </v-row>
      <v-row class="mx-0 my-6" v-else
        ><h4 class="mx-auto">Aún no tienes cursos asignados</h4></v-row
      >
    </v-container>
  </div>
</template>
<script>
  import CourseCard from '@/components/Elearning/CourseCard'

  export default {
    name: 'UserCourses',
    components: {
      CourseCard,
    },
    data() {
      return {
        courses: [],
        page: 1,
        pages: 1,
        item: {},
        loading: true,
        totalItems: 0,
        itemsPerPage: 10,
        loadingText: 'Cargando cursos',
        filterActivated: false,
        navigationElements: [
          {
            text: 'Learning',
            disabled: false,
            query: null,
            params: null,
            name: 'business/elearning/UserPanel',
            href: '/business/elearning/user-panel',
          },
          {
            text: 'Mis Cursos',
            disabled: true,
          },
        ],
      }
    },
    created() {
      this.getUserCourses()
    },
    methods: {
      async getUserCourses() {
        let queryParams = ''
        const orderString = '&order=name&by=asc'
        if (this.filterActivated) {
          let searchString = ''
          if (this.filters.search !== '') {
            searchString = '&search=' + this.filters.search
          }
          queryParams = searchString
        }
        this.$axios
          .get(
            'user-courses?with[]=uploads&with[]=cover&appends[]=lessons_count&appends[]=lessons_completed_count&appends[]=duration&appends[]=completed&appends[]=available&page=' +
              this.page +
              orderString +
              queryParams
          )
          .then((response) => {
            this.courses = response.data.data
            if (this.courses.length < 1) {
              this.loadingText = 'No hay registros'
            }
            this.courses.forEach((course) => {
              course.avatar = course.uploads.find((file) => file.type === 'avatar')
            })
            this.totalItems = response.data.total
            this.itemsPerPage = response.data.per_page
            if (response.data.from) {
              this.page = response.data.current_page
              this.pages = response.data.last_page
            } else {
              this.page = 1
              this.pages = 1
            }
            this.loading = false
          })
      },
    },
    beforeDestroy() {
      this.courses = null
      delete this.courses
    },
  }
</script>
<style lang="scss">
  .v-breadcrumbs {
    padding: 5px 10px !important;
  }
  .v-breadcrumbs__item {
    font-size: 12px;
  }
</style>
