var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-card',{staticClass:"neu-glow"},[_c('v-hover',{scopedSlots:_vm._u([{key:"default",fn:function(ref){
var hover = ref.hover;
return [_c('v-card',{staticStyle:{"height":"auto"},attrs:{"elevation":0}},[(_vm.avatar && _vm.avatar !== undefined && _vm.avatar.route)?_c('v-img',{staticClass:"grey lighten-3",attrs:{"height":"200","aspect-ratio":4 / 3,"src":_vm.avatar.route,"lazy-src":_vm.avatar.route,"contain":""},on:{"click":function($event){return _vm.handleRedirection()}}},[_c('v-expand-transition',[(hover)?_c('v-overlay',{staticStyle:{"height":"100%","justify-content":"center","align-items":"center","background-color":"primary"},attrs:{"opacity":0.8,"color":"primary","absolute":""}},[_c('span',[_vm._v(_vm._s(_vm.lessons_completed_count)+"/"+_vm._s(_vm.lessons_count)+" "+_vm._s(_vm.lessons_count > 1 ? 'lecciones' : 'lección'))]),_c('br'),_c('span',[_vm._v(_vm._s(_vm.duration)+" min")])]):_vm._e()],1)],1):_vm._e()],1)]}}])}),_c('div',{staticClass:"pos-relative"},[_c('v-btn',{staticStyle:{"bottom":"-5px"},attrs:{"absolute":"","dark":"","fab":"","right":"","x-small":"","color":"primary"}},[_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-icon',_vm._g(_vm._b({attrs:{"color":"white"},on:{"click":function($event){return _vm.handleRedirection()}}},'v-icon',attrs,false),on),[_vm._v(_vm._s(_vm.icon))])]}}])},[_c('span',[_vm._v(_vm._s(_vm.iconText))])])],1)],1),_c('h4',{staticClass:"pa-2",on:{"click":function($event){return _vm.handleRedirection()}}},[_vm._v(" "+_vm._s(_vm.name)+" ")]),_c('v-card-text',{staticClass:"pos-relative pa-2"},[_c('v-row',{staticClass:"mx-0",attrs:{"justify":"space-between","align":"center"}},[_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-progress-linear',_vm._g(_vm._b({attrs:{"height":"15","color":_vm.progressColor,"value":_vm.progressValue},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var value = ref.value;
return [_c('span',{staticClass:"caption",staticStyle:{"color":"white"}},[_vm._v(_vm._s(Math.ceil(value))+"%")])]}}],null,true)},'v-progress-linear',attrs,false),on))]}}])},[_c('span',[_vm._v(_vm._s(_vm.courseStatus))])])],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }