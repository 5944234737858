<template>
  <v-card class="neu-glow">
    <v-hover v-slot="{ hover }">
      <v-card :elevation="0" style="height: auto">
        <v-img
          @click="handleRedirection()"
          height="200"
          :aspect-ratio="4 / 3"
          :src="avatar.route"
          :lazy-src="avatar.route"
          contain
          class="grey lighten-3"
          v-if="avatar && avatar !== undefined && avatar.route"
          ><v-expand-transition>
            <v-overlay
              :opacity="0.8"
              v-if="hover"
              color="primary"
              absolute
              style="
                height: 100%;
                justify-content: center;
                align-items: center;
                background-color: primary;
              "
            >
              <span
                >{{ lessons_completed_count }}/{{ lessons_count }}
                {{ lessons_count > 1 ? 'lecciones' : 'lección' }}</span
              ><br />
              <span>{{ duration }} min</span>
            </v-overlay>
          </v-expand-transition></v-img
        >
      </v-card>
    </v-hover>

    <div class="pos-relative">
      <v-btn absolute dark fab right x-small color="primary" style="bottom: -5px">
        <v-tooltip top>
          <template v-slot:activator="{ on, attrs }">
            <v-icon v-bind="attrs" v-on="on" color="white" @click="handleRedirection()">{{
              icon
            }}</v-icon>
          </template>
          <span>{{ iconText }}</span>
        </v-tooltip>
      </v-btn>
    </div>
    <h4 class="pa-2" @click="handleRedirection()">
      {{ name }}
    </h4>

    <v-card-text class="pos-relative pa-2">
      <v-row justify="space-between" align="center" class="mx-0">
        <v-tooltip top>
          <template v-slot:activator="{ on, attrs }">
            <v-progress-linear
              v-bind="attrs"
              v-on="on"
              height="15"
              :color="progressColor"
              :value="progressValue"
              ><template v-slot:default="{ value }">
                <span class="caption" style="color: white">{{ Math.ceil(value) }}%</span>
              </template></v-progress-linear
            >
          </template>
          <span>{{ courseStatus }}</span></v-tooltip
        >
      </v-row>
    </v-card-text>
  </v-card>
</template>

<script>
  export default {
    name: 'UserCourseCard',
    computed: {
      progressValue() {
        if (this.lessons_count) {
          return Math.ceil((this.lessons_completed_count / this.lessons_count) * 100)
        } else {
          return 0
        }
      },
      progressColor() {
        if (this.progressValue === 100) {
          return 'success darken-2'
        } else {
          return 'primary'
        }
      },
      courseStatus() {
        if (this.progressValue === 100) {
          return '¡Curso completado!'
        } else {
          return '¡Continúa aprendiendo!'
        }
      },
      icon() {
        if (!this.available) {
          return 'mdi-lock-alert'
        }
        if (this.completed) {
          return 'mdi-replay'
        }
        return 'mdi-script-text-play'
      },
      iconText() {
        if (!this.available) {
          return 'Prelación: ' + this.previous.name
        }
        if (this.completed) {
          return 'Ver nuevamente'
        }
        return 'Iniciar curso'
      },
    },
    props: {
      id: {
        type: Number,
        default: 0,
      },
      avatar: {
        type: Object,
        default: null,
      },
      name: {
        type: String,
        default: null,
      },
      duration: {
        type: [Number, String],
        default: 0,
      },
      completed: {
        type: Boolean,
        default: false,
      },
      available: {
        type: Boolean,
        default: false,
      },
      lessons_completed_count: {
        type: Number,
        default: 0,
      },
      lessons_count: {
        type: Number,
        default: 1,
      },
      previous: {
        type: Object,
        default: () => {},
      },
    },
    methods: {
      handleRedirection() {
        if (this.available) {
          this.$router.push({
            name: 'business/elearning/UserCourseDetail',
            params: { courseId: this.id },
          })
        } else {
          this.$swal({
            title: 'Este curso está siendo prelado',
            text:
              'Para ver este curso, debes haber aprobado el curso: ' + this.previous.name,
            icon: 'info',
          })
        }
      },
    },
  }
</script>
<style lang="scss" scoped></style>
